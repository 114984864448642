// TODO: this should be in the ngx-core library ideally

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter ExtraBold'),
    local('Inter-ExtraBold'),
    url('InterXpo/Inter-ExtraBold.woff2') format('woff2'),
    url('InterXpo/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter Regular'),
    local('Inter-Regular'),
    url('InterXpo/Inter-Regular.woff2') format('woff2'),
    url('InterXpo/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter Light'),
    local('Inter-Light'),
    url('InterXpo/Inter-Light.woff2') format('woff2'),
    url('InterXpo/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter Thin'),
    local('Inter-Thin'),
    url('InterXpo/Inter-Thin.woff2') format('woff2'),
    url('InterXpo/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter ExtraLight'),
    local('Inter-ExtraLight'),
    url('InterXpo/Inter-ExtraLight.woff2') format('woff2'),
    url('InterXpo/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter SemiBold'),
    local('Inter-SemiBold'),
    url('InterXpo/Inter-SemiBold.woff2') format('woff2'),
    url('InterXpo/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter Black'),
    local('Inter-Black'),
    url('InterXpo/Inter-Black.woff2') format('woff2'),
    url('InterXpo/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter Bold'),
    local('Inter-Bold'),
    url('InterXpo/Inter-Bold.woff2') format('woff2'),
    url('InterXpo/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterXpo';
  src:
    local('Inter Medium'),
    local('Inter-Medium'),
    url('InterXpo/Inter-Medium.woff2') format('woff2'),
    url('InterXpo/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
